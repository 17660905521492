@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  font-family: 'Prompt', sans-serif;
}

.bg-dark {
  background-color: #0D1726; 
}
  
  .text-white {
    color: #ffffff;
  }
  
  .container {
    max-width: 1200px;
  }
  
  h5 {
    font-size: 16px;
    color: #ffffff;
  }
  
.fondoheader{
  background-image: url(/src/imagenes/fondoprueba.jpg);
}

.logocivis{
  background-image: url(/src//imagenes/civis.png);
}

.prompt-thin {
  font-family: "Prompt", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.prompt-extralight {
  font-family: "Prompt", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.prompt-light {
  font-family: "Prompt", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.prompt-regular {
  font-family: "Prompt", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.prompt-medium {
  font-family: "Prompt", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.prompt-semibold {
  font-family: "Prompt", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.prompt-bold {
  font-family: "Prompt", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.prompt-extrabold {
  font-family: "Prompt", sans-serif;
  font-weight: 800;
  font-style: normal;
}

.prompt-black {
  font-family: "Prompt", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.prompt-thin-italic {
  font-family: "Prompt", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.prompt-extralight-italic {
  font-family: "Prompt", sans-serif;
  font-weight: 200;
  font-style: italic;
}

.prompt-light-italic {
  font-family: "Prompt", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.prompt-regular-italic {
  font-family: "Prompt", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.prompt-medium-italic {
  font-family: "Prompt", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.prompt-semibold-italic {
  font-family: "Prompt", sans-serif;
  font-weight: 600;
  font-style: italic;
}

.prompt-bold-italic {
  font-family: "Prompt", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.prompt-extrabold-italic {
  font-family: "Prompt", sans-serif;
  font-weight: 800;
  font-style: italic;
}

.prompt-black-italic {
  font-family: "Prompt", sans-serif;
  font-weight: 900;
  font-style: italic;
}